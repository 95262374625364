.profile-card-wrapper {
  display: flex;
  gap: 3rem;
  padding: 3rem 2rem;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 1rem;
  // background-color: rgb(54, 127, 169);
  background-color: rgb(1,21,59);
  color: #ffffff;

  @media only screen and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
    gap: 5rem;
  }

  .profile-card-image {
    width: 15rem;
    height: 15rem;
    overflow: hidden;
    border-radius: 50%;
    margin: auto;
    border: 0.5rem solid #ffffff;

    @media only screen and (min-width: 600px) {
        width: 25rem;
        height: 25rem;
        margin: initial;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .profile-card-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;

    @media only screen and (min-width: 600px) {
      width: calc(100% - 25rem);
      gap: 1.3rem;
    }

    > div {
      display: flex;
      gap: 2rem;
      flex-direction: column;
      padding: 0 0 1rem 0;
      border-bottom: 0.01rem solid #ffffff;

      @media only screen and (min-width: 600px) {
        flex-direction: row;
        gap: 1.6rem;
      }

      h3 {
        font-weight: 600;

        @media only screen and (min-width: 600px) {
          width: 25%;
        }
      }

      p {
        @media only screen and (min-width: 600px) {
          width: 75%;
        }
      }
    }
  }
}
