.login-page {
  padding: 0;

  @media only screen and (min-width: 900px) {
    padding: 22rem 0 0 10rem;
  }

  &.register-page {
    padding: 0 0 10rem 0;
  
    @media only screen and (min-width: 900px) {
      padding: 5rem 0 0 10rem;
    }
  }

  .page-content-wrapper {
    max-width: 90%;
    margin: auto;

    @media only screen and (min-width: 768px) {
      max-width: 65%;
    }

    @media only screen and (min-width: 900px) {
      max-width: 44.7rem;
    }

    .page-title {
      font-weight: 700;
      font-size: 4rem;
      line-height: 5.5rem;
      letter-spacing: -0.0025em;
      color: rgb(1,21,59);
    }

    .page-subtitle {
      font-weight: 400;
      font-size: 2rem;
      line-height: 2.7rem;
      color: #545f7d;
    }

    .form-wrapper {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      margin-top: 6rem;

      > .form-group {
        &:last-child {
          margin-top: 0.6rem;
        }

        > .forgot-password-text {
          font-style: normal;
          font-weight: 600;
          font-size: 1.2rem;
          line-height: 1.6rem;
          letter-spacing: 0.0625em;
          text-transform: uppercase;
          color: rgb(1,21,59);
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
}
