.not-found {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    padding: 10rem 3rem;
    width: 100%;

    > svg {
        width: 20rem;
    }

    > p {
        font-size: 2.4rem;
        line-height: 3.6rem;
        text-align: center;
        max-width: 70rem;
    }

    > button {
        font-size: 1.8rem;
        font-weight: 600;
    }
}