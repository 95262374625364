.auth-input-wrapper {
  width: 100%;
  height: 5rem;
  border: 0.2rem solid rgba(84, 95, 125, 0.15);
  border-radius: 0.5rem;
  display: flex;
  background-color: #ffffff;

  &.is-sort {
    height: 4rem;
  }

  &.has-error {
    border: 0.2rem solid rgba(255, 0, 0, 0.3);
  }

  &.is-phone {
    padding: 0 1.6rem;
  }

  &.disabled {
    background-color: rgba(#111111, 0.1);
  }

  input,
  select,
  textarea {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.9rem;
    height: 100%;
    padding: 0 1.25rem;
    color: #000000;

    &::placeholder {
      opacity: 0.6;
    }
  }

  select {
    -moz-appearance: none;
    --webkit-appearance: none;
    appearance: none;

    &.no-value {
      color: #545f7d;
    }

    > .disabled {
      opacity: 0.3;
    }
  }

  .react-tagsinput {
    width: 100%;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 0 1rem;

    .react-tagsinput-input {
      display: inline-block;
      height: fit-content;
      width: fit-content;
    }
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    box-shadow: 0 0 0 1000px #ffffff inset !important;
  }

  > .toggle-button {
    display: block;
    width: 18.95%;
    height: 100%;
    background-color: transparent;
    border: none;

    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: center;
    letter-spacing: 0.0625em;
    text-transform: uppercase;
    color: rgb(1,21,59);
    cursor: pointer;
  }
}
