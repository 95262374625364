.students-home {
  .students-cards-wrapper {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5rem;

    @media only screen and (min-width: 768px) {
      justify-content: space-between;
    }
  }
}
